<template>
    <div class="number-page">

        <div class="darken-layer" ref="darken">

        </div>

        <v-panel ref="panel">
            <h1>Schiet de bal naar je geluksgetal en je eerste prijs is direct binnen!</h1>
            <p>vul je geluksgetal in:</p>
            <form @submit.prevent="goNext">
                <label><input type="text" maxlength="2" v-model="lucky" ref="input" pattern="[0-9]{1,2}" required :class="{ ready: lucky.length === 2 }" placeholder="00"/></label>
            </form>
            <a href="#" @click.prevent="random">Willekeurig geluksgetal</a>
            <ol>
                <li>Kies je geluksgetal tussen <strong>0-99</strong>.</li>
                <li>Word abonnee, Vul je gegevens in en speel mee.</li>
                <li>Schiet de bal naar je geluksgetal en zie direct welke prijs je krijgt!</li>
            </ol>
            <button slot="button" class="stl" @click="goNext" :disabled="lucky.length < 2">Schiet de bal</button>
        </v-panel>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import { gsap } from '@/vendor';
    import Panel from '@/app/components/Panel';

    export default {
        components: {
            'v-panel': Panel
        },

        data()
        {
            return {
                lucky: ''
            };
        },

        computed: {
            entry()
            {
                return this.$store.getters['entry/all'];
            },
            number()
            {
                return this.$store.getters['entry/all'].tickets[0];
            }
        },

        mounted()
        {
            this.base = { a: 0 };
            this.lucky = this.number;
        },

        beforeDestroy()
        {
        },

        methods: {
            random()
            {
                let target = Math.floor( Math.random() * 100 );
                this.base.a = Number( this.lucky );
                gsap.to( this.base, {
                    a: target,
                    duration: 0.3,
                    roundProps: 'a',
                    onUpdate: () =>
                    {
                        this.lucky = ( '0' + this.base.a ).substr( -2 );
                    } });
            },
            goNext()
            {
                if( this.lucky.length < 2 )
                {
                    console.log( 'less', this.lucky );
                    return;
                }
                this.$store.dispatch( 'entry/merge', { tickets: [ this.lucky ] });
                this.next = true;
                this.$router.push( '/gegevens/0' );
            },

            getAnimateIn()
            {
                let tl = gsap.timeline();
                tl.add( () =>
                {
                    this.$store.dispatch( 'fieldState', 'ball' );
                });
                tl.from( this.$refs.darken, { autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                tl.from( this.$refs.panel.$el, { translateZ: -200, rotateY: '-90deg', autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0.5 );
                tl.add( () =>
                {
                    this.$refs.input.focus();
                });
                return tl;
            },

            getAnimateOut()
            {
                let tl = gsap.timeline();
                tl.to( this.$refs.panel.$el.children, { autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                tl.to( this.$refs.panel.$el, { translateZ: -200, rotateY: '90deg', autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                tl.to( this.$refs.darken, { autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                if( this.next )
                {
                    tl.add( () =>
                    {
                        this.$store.dispatch( 'fieldState', 'shoot' );
                    });
                    tl.add( () =>
                    {
                    }, '+=1.2' );
                }
                return tl;
            }
        }
    };
</script>
